import React, { useState } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import ChristmasCardPopup from "../ChristmasCardPopup"

const HomeChristmasSectionWrapper = styled.div`
  width: 100%;
  position: relative;
  background: ${({ page }) => (page ? "#fff" : "#ddf0f8")};
  padding: 7px 20px;

  @media (max-width: 600px) {
    padding: 25px 20px 20px;
  }
`

const ChristmasContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222f5c;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const LeftSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  @media (max-width: 600px) {
    margin-bottom: 15px;
  }
`

const Title = styled.div`
  font-size: 28px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 900;

  @media (max-width: 600px) {
    font-size: 20px;
    text-align: center;
  }
`

const Text = styled.div`
  display: inline;
  font-size: 14px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 500;
  margin-top: 3px;
`

const TextWrapper = styled.div`
  display: inline;

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileTextWrapper = styled.div`
  display: none;
  margin-left: 15px;

  @media (max-width: 600px) {
    display: inline;
  }

  @media (max-width: 420px) {
    margin-left: 10px;
  }
`

const EGiftIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: linear-gradient(
    42.57deg,
    rgb(165, 250, 183) 0%,
    rgb(0, 196, 193) 52.23%,
    rgb(80, 181, 213) 93.37%
  );
  color: #fff;
  font-size: 16px;
  line-height: 1;
  font-family: "Museo";
  font-weight: 900;
  margin-left: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const RightSection = styled.div`
  width: 100%;
  max-width: 480px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;

  @media (max-width: 1200px) {
    max-width: 280px;
  }

  @media (max-width: 600px) {
    max-width: 100%;
    align-items: center;
  }
`

const Image = styled.img`
  width: 100%;
  max-width: 280px;
  margin: 0px;
  object-fit: contain;
  cursor: pointer;

  @media (max-width: 600px) {
    max-width: 160px;
  }

  @media (max-width: 420px) {
    max-width: 125px;
  }
`

const HomeChristmasSection = ({ intl, data, popupData, page }) => {
  const [showChristmasPopup, setShowChristmasPopup] = useState(false)
  return (
    data && (
      <HomeChristmasSectionWrapper page={page}>
        {popupData && (
          <ChristmasCardPopup
            data={popupData}
            show={showChristmasPopup}
            hide={setShowChristmasPopup}
          />
        )}
        <ChristmasContainer>
          <LeftSection>
            {data.title && (
              <Title
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
            )}
            {data.text && (
              <TextWrapper>
                <Text
                  dangerouslySetInnerHTML={{
                    __html: data.text,
                  }}
                />
                <EGiftIcon onClick={() => setShowChristmasPopup(true)}>
                  i
                </EGiftIcon>
              </TextWrapper>
            )}
          </LeftSection>
          <RightSection>
            {data.image && (
              <Image
                className="lazyload"
                data-src={data.image}
                alt={data.alt ? data.alt : ""}
                onClick={() => setShowChristmasPopup(true)}
              />
            )}
            {data.text && (
              <MobileTextWrapper>
                <Text
                  dangerouslySetInnerHTML={{
                    __html: data.text,
                  }}
                />
                <EGiftIcon onClick={() => setShowChristmasPopup(true)}>
                  i
                </EGiftIcon>
              </MobileTextWrapper>
            )}
          </RightSection>
        </ChristmasContainer>
      </HomeChristmasSectionWrapper>
    )
  )
}

export default injectIntl(HomeChristmasSection)
